import React from 'react';
import * as timeago from 'timeago.js';
import { Link, graphql } from 'gatsby';

import Bio from '../components/bio';
import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm } from '../utils/typography';

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Blog" />
      <Bio />
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug;
        return (
          <article
            key={node.fields.slug}
            style={{ marginBottom: rhythm(2) }}
          >
            <header>
              <h3
                style={{
                  marginBottom: rhythm(1 / 6),
                  lineHeight: 1.3,
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
              <small
                style={{
                  fontFamily: `JetBrains Mono, monospace`,
                  display: 'inline-block',
                  marginBottom: rhythm(1 / 6),
                  opacity: 0.8,
                }}
              >
                {timeago.format(node.frontmatter.date)} |{' '}
                {node.frontmatter.date}
                {node.frontmatter.minutes &&
                  ' | ' + node.frontmatter.minutes + ' minute read'}
              </small>
            </header>
            <section>
              <p
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        );
      })}
    </Layout>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            minutes
          }
        }
      }
    }
  }
`;
